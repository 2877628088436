import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { submitToWeb3Forms } from '../../utils/web3forms';
import toast from 'react-hot-toast';
import { countries } from 'countries-list';

interface ReservationFormProps {
  selectedSeries: string;
  selectedColor: string;
  userCountry: string;
  onCountryChange: (country: string) => void;
}

type UserRole = 'student' | 'parent' | 'teacher' | 'professional' | 'other';

const userRoles: UserRole[] = ['student', 'parent', 'teacher', 'professional', 'other'];
const inputStyles = "mt-1 block w-full rounded-md border-gray-400 shadow-sm focus:border-gray-900 focus:ring-1 focus:ring-gray-900";

export function ReservationForm({ selectedSeries, selectedColor, userCountry, onCountryChange }: ReservationFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingCountry, setIsEditingCountry] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    userRole: '' as UserRole,
    message: '',
    gdprConsent: false
  });

  const countryList = Object.entries(countries).map(([code, data]) => ({
    code,
    name: data.name,
  })).sort((a, b) => a.name.localeCompare(b.name));

  const handleCountrySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = event.target.value;
    const selectedCountry = countries[selectedCountryCode]?.name || '';
    onCountryChange(selectedCountry);
    setIsEditingCountry(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.gdprConsent) {
      toast.error('Please accept the privacy policy to continue');
      return;
    }

    try {
      setIsSubmitting(true);

      const message = `
Reservation Details:
-------------------
Series: ${selectedSeries}
Color: ${selectedColor}
User Role: ${formData.userRole}
Country: ${userCountry}

Additional Message:
${formData.message}
`.trim();

      const result = await submitToWeb3Forms({
        name: formData.fullName,
        email: formData.email,
        subject: `New Case Reservation - ${selectedSeries}`,
        message: message,
        from_name: 'JMLWORLD Reservation Form'
      });

      if (result.success) {
        toast.success('Thank you for your reservation! We will contact you shortly.');
        setFormData({
          fullName: '',
          email: '',
          userRole: '' as UserRole,
          message: '',
          gdprConsent: false
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
          Full Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="fullName"
          name="fullName"
          required
          value={formData.fullName}
          onChange={handleInputChange}
          className={inputStyles}
          disabled={isSubmitting}
        />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email Address <span className="text-red-500">*</span>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required
          value={formData.email}
          onChange={handleInputChange}
          className={inputStyles}
          disabled={isSubmitting}
        />
      </div>

      <div>
        <label htmlFor="userRole" className="block text-sm font-medium text-gray-700">
          Who are you? <span className="text-red-500">*</span>
        </label>
        <select
          id="userRole"
          name="userRole"
          required
          value={formData.userRole}
          onChange={handleInputChange}
          className={inputStyles}
          disabled={isSubmitting}
        >
          <option value="">Please select...</option>
          {userRoles.map(role => (
            <option key={role} value={role}>
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
          Country <span className="text-red-500">*</span>
        </label>
        {isEditingCountry ? (
          <select
            id="country"
            value={Object.entries(countries).find(([_, data]) => data.name === userCountry)?.[0] || ''}
            onChange={handleCountrySelect}
            className={inputStyles}
            autoFocus
            disabled={isSubmitting}
          >
            {countryList.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        ) : (
          <div className="flex items-center gap-2">
            <div className={`${inputStyles} bg-gray-50`}>
              {userCountry}
            </div>
            <button
              type="button"
              onClick={() => setIsEditingCountry(true)}
              className="p-2 text-gray-400 hover:text-gray-600"
              disabled={isSubmitting}
            >
              Edit
            </button>
          </div>
        )}
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
          Additional Message
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          value={formData.message}
          onChange={handleInputChange}
          className={inputStyles}
          disabled={isSubmitting}
        />
      </div>

      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="gdprConsent"
            name="gdprConsent"
            type="checkbox"
            required
            checked={formData.gdprConsent}
            onChange={(e) => setFormData(prev => ({ ...prev, gdprConsent: e.target.checked }))}
            className="h-4 w-4 rounded border-gray-400 text-gray-900 focus:ring-gray-900"
            disabled={isSubmitting}
          />
        </div>
        <div className="ml-3">
          <label htmlFor="gdprConsent" className="text-sm text-gray-600">
            I agree to the processing of my personal data in accordance with the Privacy Policy
            <span className="text-red-500 ml-1">*</span>
          </label>
        </div>
      </div>

      <div className="text-sm text-gray-500 mb-4">
        <span className="text-red-500">*</span> Required fields
      </div>

      <button
        type="submit"
        className="w-full apple-primary-button disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center">
            <Loader2 className="h-5 w-5 animate-spin mr-2" />
            Processing...
          </div>
        ) : (
          'Complete Reservation'
        )}
      </button>
    </form>
  );
}