export default {
  common: {
    required: '* Erforderlich',
    loading: 'Laden...',
    error: 'Etwas ist schief gelaufen',
    success: 'Erfolg!',
    submit: 'Absenden',
    cancel: 'Abbrechen',
    save: 'Speichern',
    edit: 'Bearbeiten',
    discover: 'Entdecken'
  },
  navigation: {
    cellist: 'Cellist',
    violinist: 'Geiger',
    violist: 'Bratschist',
    about: 'Über uns',
    contact: 'Kontakt',
    reserve: 'Reservieren',
    getStarted: 'Reservieren'
  },
  hero: {
    welcome: 'Willkommen bei',
    subtitle: 'JMLWORLD bietet JMLs ultraleichte Carbonkoffer für Celli - Vielseitiger Schutz für Streichinstrumente, der das Leben von Musikern erleichtert',
    cl6: {
      title: 'CL6 Serie',
      imageAlt: 'JML CL6 Serie Carbon Cello Koffer'
    },
    cl7: {
      title: 'CL7 Serie',
      imageAlt: 'JML CL7 Serie Carbon Cello Koffer'
    }
  },
  features: {
    title: 'Eigenschaften',
    ultraLight: {
      title: 'Ultraleichtes Design',
      desc: 'Erleben Sie unvergleichliche Mobilität mit unserem leichten Carbon-Design.'
    },
    protection: {
      title: 'Überlegener Schutz',
      desc: 'Die mehrschichtige Carbonstruktur bietet außergewöhnliche Festigkeit und Stoßfestigkeit.'
    },
    shipping: {
      title: 'Versand',
      desc: 'Sicher bis zu Ihrer Haustür geliefert.'
    }
  },
  contact: {
    title: 'Kontakt aufnehmen',
    message: 'Wir freuen uns von Ihnen zu hören',
    cta: 'Kontaktieren Sie uns',
    form: {
      fullName: 'Vollständiger Name',
      email: 'E-Mail-Adresse',
      subject: 'Betreff',
      message: 'Ihre Nachricht',
      gdpr: 'Ich stimme der Verarbeitung meiner persönlichen Daten gemäß der Datenschutzerklärung zu',
      required: '* Pflichtfelder',
      submit: 'Nachricht senden',
      success: 'Nachricht erfolgreich gesendet! Wir werden uns bald bei Ihnen melden.',
      error: 'Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.'
    }
  },
  reserve: {
    title: 'Reservieren Sie Ihren Koffer',
    subtitle: 'Wählen Sie Ihr Instrument und passen Sie Ihre Bestellung an.',
    howItWorks: 'So funktioniert\'s',
    steps: {
      instrument: 'Wählen Sie Ihr Instrument',
      preferences: 'Wählen Sie Ihre Präferenzen',
      order: 'Schließen Sie Ihre Bestellung ab'
    },
    form: {
      series: {
        title: 'Wählen Sie Ihre Serie',
        subtitle: 'Wählen Sie die perfekte Serie für Ihre Bedürfnisse'
      },
      color: {
        title: 'Wählen Sie Ihre Farbe',
        subtitle: 'Drücken Sie sich aus - wählen Sie Ihre Farbe'
      },
      fullName: 'Vollständiger Name',
      email: 'E-Mail-Adresse',
      userRole: 'Wer sind Sie?',
      country: 'Land',
      message: 'Zusätzliche Nachricht',
      gdpr: 'Ich stimme der Verarbeitung meiner persönlichen Daten zu',
      submit: 'Reservierung abschließen',
      success: 'Vielen Dank für Ihre Reservierung! Wir werden uns in Kürze bei Ihnen melden.',
      error: 'Reservierung fehlgeschlagen. Bitte versuchen Sie es erneut.'
    }
  },
  notification: {
    title: 'Bleiben Sie informiert',
    subtitle: 'Melden Sie sich für unseren Newsletter an',
    email: 'E-Mail eingeben',
    submit: 'Benachrichtigen Sie mich',
    success: 'Danke! Wir informieren Sie, sobald verfügbar.',
    error: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.'
  },
  footer: {
    tagline: 'Elevoria Ltd ist ein in Großbritannien ansässiges Unternehmen, das sich auf den Verkauf und Vertrieb von JML-Hardcases für Streichinstrumente über JMLWORLD.COM spezialisiert hat. Für Anfragen außerhalb Großbritanniens kontaktieren Sie uns bitte.',
    hardCaseFor: 'Hardcase für',
    company: 'Unternehmen',
    legal: 'Rechtliches',
    rights: '© {year} JMLWORLD wird betrieben von Elevoria Ltd. Alle Rechte vorbehalten.'
  }
};