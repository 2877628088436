import { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from '../contexts/TranslationContext';

interface NavigationProps {
  setLocale: (locale: string) => void;
  setCurrentPage: (page: string) => void;
}

export function Navigation({ setLocale, setCurrentPage }: NavigationProps) {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavClick = (e: React.MouseEvent, path: string) => {
    e.preventDefault();
    setCurrentPage(path.replace('/', '') || 'home');
    navigate(path);
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  };

  const navLinks = [
    { id: 'instruments', links: [
      { href: '/cellist', messageId: 'navigation.cellist' },
      { href: '/violinist', messageId: 'navigation.violinist' },
      { href: '/violist', messageId: 'navigation.violist' }
    ]},
    { id: 'company', links: [
      { href: '/about', messageId: 'navigation.about' },
      { href: '/contact-us', messageId: 'navigation.contact' }
    ]}
  ];

  return (
    <nav 
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-[#ccc9bf] border-b border-gray-200/50' : 'bg-[#ccc9bf]'
      }`}
      role="navigation" 
      aria-label="Main navigation"
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between h-12 items-center px-4 sm:px-6 lg:px-8">
          <div className="flex items-center">
            <a 
              href="/"
              onClick={(e) => handleNavClick(e, '/')}
              className="text-xl font-bold tracking-tight text-gray-900"
            >
              JMLWORLD.COM
            </a>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map(group => (
              <div key={group.id} className="flex space-x-6">
                {group.links.map(link => (
                  <a
                    key={link.messageId}
                    href={link.href}
                    onClick={(e) => handleNavClick(e, link.href)}
                    className="text-sm text-gray-900 hover:text-gray-600 transition-colors"
                  >
                    {t(link.messageId)}
                  </a>
                ))}
              </div>
            ))}
            <LanguageSelector onLanguageChange={setLocale} />
            <button 
              onClick={(e) => handleNavClick(e, '/reserve')}
              className="apple-primary-button"
            >
              {t('navigation.getStarted')}
            </button>
          </div>

          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2"
            aria-expanded={isMenuOpen}
            aria-controls="mobile-menu"
          >
            {isMenuOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
          </button>
        </div>

        {isMenuOpen && (
          <div 
            id="mobile-menu" 
            className="bg-[#ccc9bf] md:hidden absolute w-full border-b border-gray-200/50"
          >
            <div className="px-4 pt-2 pb-3 space-y-1">
              {navLinks.map(group => (
                <div key={group.id} className="py-2 space-y-2">
                  {group.links.map(link => (
                    <a
                      key={link.messageId}
                      href={link.href}
                      onClick={(e) => handleNavClick(e, link.href)}
                      className="block text-base text-gray-900"
                    >
                      {t(link.messageId)}
                    </a>
                  ))}
                </div>
              ))}
              <div className="py-2">
                <LanguageSelector onLanguageChange={setLocale} />
              </div>
              <button 
                onClick={(e) => handleNavClick(e, '/reserve')}
                className="apple-primary-button w-full justify-center"
              >
                {t('navigation.getStarted')}
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}