import { FormattedMessage } from 'react-intl';

export function PrivacyPage() {
  return (
    <div className="min-h-screen pt-32 pb-16 relative">
      <div className="absolute inset-0 bg-gradient-radial from-gray-50 to-white overflow-hidden">
        <div className="absolute inset-0 bg-grid opacity-[0.015]" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-semibold tracking-tight text-gray-900 mb-8 text-center">
            Privacy Policy
          </h1>
          
          <div className="prose prose-gray max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <p>
                Elevoria Ltd, a UK-based company specializing in the sale and distribution of JML hard cases for string instruments via JMLWORLD.COM, is committed to protecting your privacy. This policy outlines how we collect, use, and protect your personal data.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">2. Data Controller</h2>
              <p>
                Elevoria Ltd acts as the data controller responsible for the processing of personal data collected through JMLWORLD.COM.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">3. Information We Collect</h2>
              <h3 className="text-xl font-medium mb-2">Personal Data</h3>
              <ul className="list-disc pl-6 mb-4">
                <li>Name and contact details</li>
                <li>Delivery address</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Payment information</li>
              </ul>
              <h3 className="text-xl font-medium mb-2">Technical Data</h3>
              <ul className="list-disc pl-6">
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Device information</li>
                <li>Browsing behavior</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">4. How We Collect Data</h2>
              <ul className="list-disc pl-6">
                <li>Direct interactions through our website forms</li>
                <li>Account creation and management</li>
                <li>Purchase transactions</li>
                <li>Customer service communications</li>
                <li>Automated technologies and cookies</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">5. Use of Personal Data</h2>
              <ul className="list-disc pl-6">
                <li>Processing and fulfilling orders</li>
                <li>Providing customer support</li>
                <li>Sending order updates and notifications</li>
                <li>Marketing communications (with consent)</li>
                <li>Legal and regulatory compliance</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">6. Legal Basis for Processing</h2>
              <ul className="list-disc pl-6">
                <li>Consent for marketing communications</li>
                <li>Contractual necessity for order processing</li>
                <li>Legal obligations for regulatory compliance</li>
                <li>Legitimate interests for business operations</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">7. Data Security</h2>
              <p>
                We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">8. Your Rights</h2>
              <ul className="list-disc pl-6">
                <li>Right to access your personal data</li>
                <li>Right to correct inaccurate data</li>
                <li>Right to request deletion of your data</li>
                <li>Right to object to processing</li>
                <li>Right to data portability</li>
                <li>Right to withdraw consent</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">9. Contact Information</h2>
              <p>
                For any privacy-related inquiries, please contact us at:
              </p>
              <div className="mt-4">
                <p><strong>Elevoria Ltd</strong></p>
                <p>Email: privacy@jmlworld.com</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">10. Changes to This Policy</h2>
              <p>
                We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">11. Complaints</h2>
              <p>
                You have the right to lodge a complaint with the Information Commissioner's Office (ICO) if you believe your data has been mishandled.
              </p>
              <p className="mt-4">
                <a 
                  href="https://ico.org.uk/make-a-complaint/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-800"
                >
                  Visit ICO website
                </a>
              </p>
            </section>

            <div className="text-sm text-gray-500 mt-12 pt-8 border-t">
              Last updated: {new Date().toLocaleDateString('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}