import { FormattedMessage } from 'react-intl';

export function AboutPage() {
  return (
    <div className="min-h-screen pt-32 pb-16 relative">
      <div className="absolute inset-0 bg-gradient-radial from-gray-50 to-white overflow-hidden">
        <div className="absolute inset-0 bg-grid opacity-[0.015]" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-semibold tracking-tight text-gray-900 mb-6 text-center">
            About Us
          </h1>
          
          <div className="prose prose-gray max-w-none">
            <div className="space-y-6 text-gray-600">
              <p className="text-xl font-medium text-gray-900">
                Welcome to JMLWORLD.COM, where we are dedicated to providing high-quality, ultra-lightweight hard cases for string instruments at accessible prices.
              </p>

              <p>
                As parents of passionate musicians, we personally struggled to find instrument cases that offered both superior protection and ease of transport. The market options were limited to expensive cases ranging from £2,000 to £4,000, which was beyond the reach of many families and musicians like us.
              </p>

              <p>
                Realizing this significant gap, we conducted extensive research involving young and seasoned musicians, parents, teachers, and music schools. The consensus was clear: there is a genuine need for lightweight yet durable hard cases that don't compromise on quality or affordability.
              </p>

              <p>
                With the support of our friends, educators, and esteemed institutions, we decided to take action. We established Elevoria Ltd, a UK-based company committed to enhancing the lives of musicians. Our journey led us to collaborate with JML, a renowned specialist in designing and manufacturing hard cases using the latest carbon fibre technology.
              </p>

              <p>
                Through this partnership, we're thrilled to bring you a range of affordable, ultra-lightweight hard cases that make transporting and protecting your precious instruments easier than ever. Our cases are meticulously crafted to meet the high standards required by musicians, without the exorbitant price tag.
              </p>

              <p>
                Our mission is simple: to support the musical community by offering exceptional products that combine innovation, style, and practicality. We believe that every musician deserves access to quality equipment that supports their passion.
              </p>

              <p>
                Thank you for joining us on this exciting journey. Together, we're making a meaningful difference in the world of music.
              </p>

              <div className="border-t border-gray-200 pt-8 mt-8">
                <p className="font-semibold text-gray-900">Elevoria Ltd</p>
                <p className="italic">Bringing affordable excellence to musicians everywhere.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}