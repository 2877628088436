import { useTranslation } from '../../contexts/TranslationContext';

export function ContactHero() {
  const { t } = useTranslation();
  
  return (
    <div className="text-center mb-12">
      <h1 className="text-4xl font-semibold tracking-tight text-gray-900 mb-4">
        {t('contact.title')}
      </h1>
      <p className="text-xl text-gray-600 max-w-2xl mx-auto">
        {t('contact.message')}
      </p>
    </div>
  );
}