import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReservationForm } from '../components/forms/ReservationForm';

const CL6_COLORS = [
  {
    id: 'black',
    name: 'CL6 Black',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-black.png'
  },
  {
    id: 'white',
    name: 'CL6 White',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-white.png'
  },
  {
    id: 'silver',
    name: 'CL6 Silver',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-silver.png'
  },
  {
    id: 'red',
    name: 'CL6 Red',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-red.png'
  },
  {
    id: 'green',
    name: 'CL6 Green',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-green.png'
  },
  {
    id: 'yellow',
    name: 'CL6 Yellow',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-yellow.png'
  },
  {
    id: 'pink',
    name: 'CL6 Pink',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-pink.png'
  },
  {
    id: 'purple',
    name: 'CL6 Purple',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-purple.png'
  }
];

const CL7_COLORS = [
  {
    id: 'sky-black',
    name: 'CL7 Sky Black',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-sky-black.png'
  },
  {
    id: 'litchi-white',
    name: 'CL7 Litchi White',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-litchi-white.png'
  },
  {
    id: 'alizarin',
    name: 'CL7 Alizarin',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-alizarin.png'
  },
  {
    id: 'peacock-blue',
    name: 'CL7 Peacock Blue',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-peacock-blue.png'
  },
  {
    id: 'pure-green',
    name: 'CL7 Pure Green',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-pure-green.png'
  },
  {
    id: 'denim-blue',
    name: 'CL7 Denim Blue',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-denim-blue.png'
  },
  {
    id: 'hyacinth',
    name: 'CL7 Hyacinth',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-hyacinth.png'
  },
  {
    id: 'night-purple',
    name: 'CL7 Night Purple',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-night-purple.png'
  }
];

const SERIES = [
  {
    id: 'cl6',
    name: 'CL6 ULTRALIGHT SERIES',
    description: 'Ultra-light carbon fibre cello case weighing only 2.8kg',
    price: '£1,190',
    colors: CL6_COLORS
  },
  {
    id: 'cl7',
    name: 'CL7 ULTRALIGHT SERIES',
    description: 'Premium carbon fibre cello case with enhanced features',
    price: '£1,390',
    colors: CL7_COLORS
  }
];

const INSTRUMENTS = [
  {
    id: 'cello',
    name: 'Cello Case',
    description: 'We are only accepting reservations with online payment. Estimated delivery time is approximately 20 days from order confirmation.'
  },
  {
    id: 'violin',
    name: 'Violin Case',
    description: 'Coming soon'
  },
  {
    id: 'viola',
    name: 'Viola Case',
    description: 'Coming soon'
  }
];

export function ReservePage() {
  const [activeTab, setActiveTab] = useState('cello');
  const [selectedSeries, setSelectedSeries] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [userCountry, setUserCountry] = useState('United Kingdom');

  useEffect(() => {
    setSelectedColor('');
  }, [selectedSeries]);

  const shouldShowContactForm = selectedSeries && selectedColor;

  return (
    <div className="min-h-screen pt-32 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-semibold mb-4">Reserve Your Case</h1>
            <p className="text-gray-600">Select your instrument and customize your order.</p>
          </div>

          {/* How It Works Section */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-6 text-center">How It Works</h2>
            <div className="grid gap-6 md:grid-cols-3">
              {[
                { step: 1, title: 'Select your instrument' },
                { step: 2, title: 'Choose your preferences' },
                { step: 3, title: 'Complete your order' }
              ].map(({ step, title }) => (
                <div key={step} className="text-center">
                  <div className="w-10 h-10 bg-gray-900 text-white rounded-full flex items-center justify-center mx-auto mb-4">
                    {step}
                  </div>
                  <h3 className="font-medium">{title}</h3>
                </div>
              ))}
            </div>
          </div>

          {/* Instrument Selection */}
          <div className="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
            <div className="border-b border-gray-200">
              <div className="flex">
                {INSTRUMENTS.map((instrument) => (
                  <button
                    key={instrument.id}
                    onClick={() => setActiveTab(instrument.id)}
                    className={`flex-1 px-6 py-4 text-center text-sm font-medium ${
                      activeTab === instrument.id
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    {instrument.name}
                  </button>
                ))}
              </div>
            </div>

            <div className="p-8">
              {activeTab === 'cello' ? (
                <div className="space-y-8">
                  {/* Series Selection */}
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-6">
                      Select Your Series
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {SERIES.map((series) => (
                        <div key={series.id} className="relative">
                          <input
                            type="radio"
                            name="series-choice"
                            value={series.id}
                            id={`series-${series.id}`}
                            className="sr-only peer"
                            checked={selectedSeries === series.id}
                            onChange={() => setSelectedSeries(series.id)}
                          />
                          <label
                            htmlFor={`series-${series.id}`}
                            className="block p-6 rounded-xl border-2 cursor-pointer transition-all
                                     peer-checked:border-gray-900 peer-checked:bg-gray-50
                                     hover:border-gray-400"
                          >
                            <div className="font-semibold text-lg mb-2">{series.name}</div>
                            <div className="text-gray-600 text-sm mb-3">{series.description}</div>
                            <div className="text-gray-900 font-bold">{series.price}</div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Color Selection */}
                  {selectedSeries && (
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900 mb-3">
                        Select Your Color
                      </h3>
                      <p className="text-gray-600 mb-6">
                        Express yourself - choose your colour.
                      </p>

                      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                        {SERIES.find(s => s.id === selectedSeries)?.colors.map((color) => (
                          <div key={color.id} className="relative">
                            <input
                              type="radio"
                              name="color-choice"
                              value={color.id}
                              id={color.id}
                              className="sr-only peer"
                              checked={selectedColor === color.id}
                              onChange={() => setSelectedColor(color.id)}
                            />
                            <label
                              htmlFor={color.id}
                              className="block relative aspect-square overflow-hidden rounded-lg cursor-pointer 
                                       ring-2 ring-transparent peer-checked:ring-black transition-all
                                       hover:opacity-90"
                            >
                              <img
                                src={color.image}
                                alt={color.name}
                                className="w-full h-full object-cover"
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Reservation Form */}
                  {shouldShowContactForm && (
                    <div className="border-t pt-8">
                      <ReservationForm
                        selectedSeries={selectedSeries}
                        selectedColor={selectedColor}
                        userCountry={userCountry}
                        onCountryChange={setUserCountry}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center py-8">
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">
                    {INSTRUMENTS.find(i => i.id === activeTab)?.name}
                  </h3>
                  <p className="text-gray-600">
                    {INSTRUMENTS.find(i => i.id === activeTab)?.description}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}