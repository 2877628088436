export const messages = {
  en: {
    // Navigation
    'nav.cellist': 'Cellist',
    'nav.violinist': 'Violinist',
    'nav.violist': 'Violist',
    'nav.about': 'About',
    'nav.contact': 'Contact',
    'nav.reserve': 'Reserve',
    'nav.getStarted': 'Reserve',

    // Hero
    'hero.welcome': 'Welcome to',
    'hero.subtitle': 'JMLWORLD Offers JML\'s Ultra-Light Carbon Fibre Cello Hard Cases—Versatile Protection for String Instruments Making Musicians\' Lives Easier',
    'hero.discover': 'Discover',
    'hero.cl6.title': 'CL6 Series',
    'hero.cl7.title': 'CL7 Series',

    // Features
    'features.title': 'Features',
    'features.ultraLight.title': 'Ultra-Light Design',
    'features.ultraLight.desc': 'Experience unparalleled mobility with our lightweight carbon fibre design.',
    'features.protection.title': 'Superior Protection',
    'features.protection.desc': 'Multi-layer carbon fibre construction provides exceptional strength and impact resistance.',
    'features.shipping.title': 'Shipping',
    'features.shipping.desc': 'Safely delivered right to your doorstep.',

    // Contact Form
    'contact.title': 'Get in Touch',
    'contact.message': 'We\'d love to hear from you',
    'contact.getInTouch': 'Contact Information',
    'contact.form.fullName': 'Full name',
    'contact.form.email': 'Email address',
    'contact.form.userRole': 'Who are you?',
    'contact.form.userRole.student': 'I am a student',
    'contact.form.userRole.parent': 'I am a parent',
    'contact.form.userRole.teacher': 'I am a teacher',
    'contact.form.userRole.professional': 'I am a music professional',
    'contact.form.userRole.other': 'Other',
    'contact.form.message': 'Your message',
    'contact.form.gdpr': 'I agree to the processing of my personal data in accordance with the Privacy Policy',
    'contact.form.required': '* Required fields',
    'contact.form.submit': 'Send Message',
    'contact.cta': 'Contact Us',

    // Footer
    'footer.tagline': 'Elevoria Ltd is a UK-based limited company specializing in the sale and distribution of JML hard cases for string instruments via JMLWORLD.COM. For any inquiries outside the UK, please contact us.',
    'footer.hardCaseFor': 'Hard Case for',
    'footer.company': 'Company',
    'footer.legal': 'Legal',
    'footer.rights': '© {year} JMLWORLD is powered by Elevoria Ltd.. All rights reserved.',

    // Cellist Page
    'cellist.title': 'JML Carbon Fibre Cello Cases',
    'cellist.subtitle': 'Experience the perfect blend of protection and portability',
    'cellist.selectColor': 'Express yourself - choose your colour.',
    'cellist.deliveryInfo.title': 'Delivery Information',
    'cellist.deliveryInfo.text': 'Due to high demand, JMLWorld is currently accepting reservations only.',

    // Reserve Page
    'reserve.title': 'Reserve Your Case',
    'reserve.subtitle': 'Select your instrument and customize your order.',
    'reserve.howItWorks': 'How It Works',
    'reserve.step1': 'Select your instrument',
    'reserve.step2': 'Choose your preferences',
    'reserve.step3': 'Complete your order',
    'reserve.selectSeries': 'Select Your Series',
    'reserve.selectColor': 'Select Your Color',
    'reserve.completeReservation': 'Complete Reservation',
    'reserve.preOrder': 'We are only accepting reservations with online payment. Estimated delivery time is approximately 20 days from order confirmation.'
  }
};