export default {
  common: {
    required: '* Obligatoire',
    loading: 'Chargement...',
    error: 'Une erreur est survenue',
    success: 'Succès !',
    submit: 'Envoyer',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    edit: 'Modifier',
    discover: 'Découvrir'
  },
  navigation: {
    cellist: 'Violoncelliste',
    violinist: 'Violoniste',
    violist: 'Altiste',
    about: 'À propos',
    contact: 'Contact',
    reserve: 'Réserver',
    getStarted: 'Réserver'
  },
  hero: {
    welcome: 'Bienvenue sur',
    subtitle: 'JMLWORLD propose des étuis ultra-légers en fibre de carbone JML pour violoncelle - Une protection polyvalente pour les instruments à cordes facilitant la vie des musiciens',
    cl6: {
      title: 'Série CL6',
      imageAlt: 'Étui rigide JML CL6 en fibre de carbone pour violoncelle'
    },
    cl7: {
      title: 'Série CL7',
      imageAlt: 'Étui rigide JML CL7 en fibre de carbone pour violoncelle'
    }
  },
  features: {
    title: 'Caractéristiques',
    ultraLight: {
      title: 'Design Ultra-Léger',
      desc: 'Profitez d\'une mobilité inégalée grâce à notre design en fibre de carbone léger.'
    },
    protection: {
      title: 'Protection Supérieure',
      desc: 'La construction multicouche en fibre de carbone offre une résistance et une protection exceptionnelles aux chocs.'
    },
    shipping: {
      title: 'Livraison',
      desc: 'Livré en toute sécurité à votre porte.'
    }
  },
  contact: {
    title: 'Contactez-nous',
    message: 'Nous aimerions avoir de vos nouvelles',
    cta: 'Nous Contacter',
    form: {
      fullName: 'Nom complet',
      email: 'Adresse e-mail',
      subject: 'Sujet',
      message: 'Votre message',
      gdpr: 'J\'accepte le traitement de mes données personnelles conformément à la politique de confidentialité',
      required: '* Champs obligatoires',
      submit: 'Envoyer le message',
      success: 'Message envoyé avec succès ! Nous vous répondrons bientôt.',
      error: 'Échec de l\'envoi du message. Veuillez réessayer.'
    }
  },
  reserve: {
    title: 'Réservez Votre Étui',
    subtitle: 'Sélectionnez votre instrument et personnalisez votre commande.',
    howItWorks: 'Comment ça marche',
    steps: {
      instrument: 'Sélectionnez votre instrument',
      preferences: 'Choisissez vos préférences',
      order: 'Complétez votre commande'
    },
    form: {
      series: {
        title: 'Sélectionnez Votre Série',
        subtitle: 'Choisissez la série parfaite pour vos besoins'
      },
      color: {
        title: 'Sélectionnez Votre Couleur',
        subtitle: 'Exprimez-vous - choisissez votre couleur'
      },
      fullName: 'Nom Complet',
      email: 'Adresse E-mail',
      userRole: 'Qui êtes-vous ?',
      country: 'Pays',
      message: 'Message Supplémentaire',
      gdpr: 'J\'accepte le traitement de mes données personnelles',
      submit: 'Compléter la Réservation',
      success: 'Merci pour votre réservation ! Nous vous contacterons bientôt.',
      error: 'Échec de la réservation. Veuillez réessayer.'
    }
  },
  notification: {
    title: 'Restez Informé',
    subtitle: 'Inscrivez-vous à notre newsletter',
    email: 'Entrez votre e-mail',
    submit: 'Me Notifier',
    success: 'Merci ! Nous vous informerons dès que disponible.',
    error: 'Échec de l\'inscription. Veuillez réessayer.'
  },
  footer: {
    tagline: 'Elevoria Ltd est une société basée au Royaume-Uni spécialisée dans la vente et la distribution d\'étuis rigides JML pour instruments à cordes via JMLWORLD.COM. Pour toute demande hors Royaume-Uni, veuillez nous contacter.',
    hardCaseFor: 'Étui Rigide pour',
    company: 'Entreprise',
    legal: 'Mentions Légales',
    rights: '© {year} JMLWORLD est propulsé par Elevoria Ltd. Tous droits réservés.'
  }
};