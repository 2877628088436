import { createContext, useContext, useState } from 'react';
import en from '../i18n/locales/en';
import fr from '../i18n/locales/fr';
import de from '../i18n/locales/de';
import zh from '../i18n/locales/zh';

type TranslationKey = string;
type TranslationParams = Record<string, string | number>;

interface TranslationContextType {
  locale: string;
  setLocale: (locale: string) => void;
  t: (key: TranslationKey, params?: TranslationParams) => string;
}

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

const messages = { en, fr, de, zh };

export function TranslationProvider({ children }: { children: React.ReactNode }) {
  const [locale, setLocale] = useState(
    localStorage.getItem('preferred_language') || 
    navigator.language.split('-')[0] || 
    'en'
  );

  const t = (key: TranslationKey, params?: TranslationParams): string => {
    const keys = key.split('.');
    let translation = keys.reduce((obj, key) => obj?.[key], messages[locale] as any);

    if (typeof translation !== 'string') {
      console.warn(`Translation key not found: ${key}`);
      return key;
    }

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        translation = translation.replace(`{${key}}`, String(value));
      });
    }

    return translation;
  };

  const handleSetLocale = (newLocale: string) => {
    localStorage.setItem('preferred_language', newLocale);
    setLocale(newLocale);
  };

  return (
    <TranslationContext.Provider value={{ locale, setLocale: handleSetLocale, t }}>
      {children}
    </TranslationContext.Provider>
  );
}

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};