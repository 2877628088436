import { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface CelloCase {
  id: number;
  image: string;
  color: string;
  name: string;
}

const CL6_CASES: CelloCase[] = [
  {
    id: 1,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-black.png',
    color: 'Black',
    name: 'CL6 Black'
  },
  {
    id: 2,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-white.png',
    color: 'White',
    name: 'CL6 White'
  },
  {
    id: 3,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-silver.png',
    color: 'Silver',
    name: 'CL6 Silver'
  },
  {
    id: 4,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-red.png',
    color: 'Red',
    name: 'CL6 Red'
  },
  {
    id: 5,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-green.png',
    color: 'Green',
    name: 'CL6 Green'
  },
  {
    id: 6,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-yellow.png',
    color: 'Yellow',
    name: 'CL6 Yellow'
  },
  {
    id: 7,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-pink.png',
    color: 'Pink',
    name: 'CL6 Pink'
  },
  {
    id: 8,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-purple.png',
    color: 'Purple',
    name: 'CL6 Purple'
  }
];

const CL7_CASES: CelloCase[] = [
  {
    id: 1,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-sky-black.png',
    color: 'Sky Black',
    name: 'CL7 Sky Black'
  },
  {
    id: 2,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-litchi-white.png',
    color: 'Litchi White',
    name: 'CL7 Litchi White'
  },
  {
    id: 3,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-alizarin.png',
    color: 'Alizarin',
    name: 'CL7 Alizarin'
  },
  {
    id: 4,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-peacock-blue.png',
    color: 'Peacock Blue',
    name: 'CL7 Peacock Blue'
  },
  {
    id: 5,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-pure-green.png',
    color: 'Pure Green',
    name: 'CL7 Pure Green'
  },
  {
    id: 6,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-denim-blue.png',
    color: 'Denim Blue',
    name: 'CL7 Denim Blue'
  },
  {
    id: 7,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-hyacinth.png',
    color: 'Hyacinth',
    name: 'CL7 Hyacinth'
  },
  {
    id: 8,
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL7_series_collection/jml-world-cello-carbon-fibre-hardcase-cl7-night-purple.png',
    color: 'Night Purple',
    name: 'CL7 Night Purple'
  }
];

interface CelloSliderProps {
  series: 'cl6' | 'cl7';
}

export function CelloSlider({ series }: CelloSliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const cases = series === 'cl6' ? CL6_CASES : CL7_CASES;

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => 
      prevIndex === cases.length - 1 ? 0 : prevIndex + 1
    );
  }, [cases.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? cases.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isAutoPlaying) {
      intervalId = setInterval(nextSlide, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoPlaying, nextSlide]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [series]);

  return (
    <div 
      className="relative w-full lg:w-[400px] aspect-square"
      onMouseEnter={() => setIsAutoPlaying(false)}
      onMouseLeave={() => setIsAutoPlaying(true)}
    >
      <div className="absolute -inset-4 bg-gradient-to-b from-white/40 to-white/0 backdrop-blur-[2px] rounded-3xl" />
      
      {/* Main Image */}
      <div className="relative w-full h-full">
        {cases.map((cello, index) => (
          <div
            key={cello.id}
            className={`absolute w-full h-full transition-opacity duration-500 ${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
            aria-hidden={index !== currentIndex}
          >
            <img
              src={cello.image}
              alt={`JML ${cello.name} Cello Case`}
              className="w-full h-full object-contain rounded-2xl"
              loading={index === 0 ? 'eager' : 'lazy'}
            />
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        onClick={prevSlide}
        className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-white transition-colors"
        aria-label="Previous case"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-white transition-colors"
        aria-label="Next case"
      >
        <ChevronRight className="w-6 h-6" />
      </button>

      {/* Dots Navigation */}
      <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 flex flex-wrap justify-center gap-2 w-full max-w-[300px]">
        {cases.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-2 h-2 rounded-full transition-all ${
              index === currentIndex 
                ? 'bg-gray-900 w-4' 
                : 'bg-gray-400 hover:bg-gray-600'
            }`}
            aria-label={`Go to slide ${index + 1}`}
            aria-current={index === currentIndex}
          />
        ))}
      </div>
    </div>
  );
}