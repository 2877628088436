import { FormattedMessage } from 'react-intl';
import { Mail } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ContactProps {
  setCurrentPage?: (page: string) => void;
}

export function Contact({ setCurrentPage }: ContactProps) {
  const navigate = useNavigate();

  const handleContactClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (setCurrentPage) {
      setCurrentPage('contact-us');
    }
    navigate('/contact-us');
    window.scrollTo(0, 0);
  };

  return (
    <section id="contact" className="py-16 bg-gray-50" aria-labelledby="contact-heading">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h2 
            id="contact-heading" 
            className="text-3xl font-semibold tracking-tight mb-4"
          >
            <FormattedMessage id="contact.title" />
          </h2>
          <p className="text-gray-600 mb-8">
            <FormattedMessage id="contact.message" />
          </p>
          <button
            onClick={handleContactClick}
            className="apple-primary-button inline-flex items-center group"
          >
            <Mail className="mr-2 h-4 w-4" />
            <FormattedMessage id="contact.cta" />
            <span className="ml-2 transition-transform group-hover:translate-x-1">→</span>
          </button>
        </div>
      </div>
    </section>
  );
}