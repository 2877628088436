import { LucideIcon } from 'lucide-react';

interface TabProps {
  icon: LucideIcon;
  label: string;
  count?: number;
  isActive: boolean;
  onClick: () => void;
}

export function Tab({ icon: Icon, label, count, isActive, onClick }: TabProps) {
  return (
    <button
      onClick={onClick}
      className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
        isActive 
          ? 'bg-gray-900 text-white' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
    >
      <Icon className="w-5 h-5 mr-2" />
      <span>{label}</span>
      {count !== undefined && (
        <span className={`ml-2 px-2 py-0.5 rounded-full text-sm ${
          isActive ? 'bg-white text-gray-900' : 'bg-gray-200 text-gray-600'
        }`}>
          {count}
        </span>
      )}
    </button>
  );
}