export default {
  common: {
    required: '* 必填',
    loading: '加载中...',
    error: '出错了',
    success: '成功！',
    submit: '提交',
    cancel: '取消',
    save: '保存',
    edit: '编辑',
    discover: '探索'
  },
  navigation: {
    cellist: '大提琴家',
    violinist: '小提琴家',
    violist: '中提琴家',
    about: '关于我们',
    contact: '联系我们',
    reserve: '预订',
    getStarted: '预订'
  },
  hero: {
    welcome: '欢迎来到',
    subtitle: 'JMLWORLD提供JML超轻碳纤维大提琴琴盒—为弦乐器提供多功能保护，让音乐家的生活更轻松',
    cl6: {
      title: 'CL6系列',
      imageAlt: 'JML CL6系列碳纤维大提琴硬琴盒'
    },
    cl7: {
      title: 'CL7系列',
      imageAlt: 'JML CL7系列碳纤维大提琴硬琴盒'
    }
  },
  features: {
    title: '特点',
    ultraLight: {
      title: '超轻设计',
      desc: '体验我们轻量碳纤维设计带来的无与伦比的机动性。'
    },
    protection: {
      title: '卓越保护',
      desc: '多层碳纤维结构提供卓越的强度和抗冲击性。'
    },
    shipping: {
      title: '配送',
      desc: '安全送达您的门前。'
    }
  },
  contact: {
    title: '联系我们',
    message: '我们期待听到您的声音',
    cta: '联系我们',
    form: {
      fullName: '姓名',
      email: '电子邮箱',
      subject: '主题',
      message: '您的留言',
      gdpr: '我同意根据隐私政策处理我的个人数据',
      required: '* 必填字段',
      submit: '发送消息',
      success: '消息发送成功！我们将尽快回复您。',
      error: '消息发送失败。请重试。'
    }
  },
  reserve: {
    title: '预订您的琴盒',
    subtitle: '选择您的乐器并定制您的订单。',
    howItWorks: '如何操作',
    steps: {
      instrument: '选择您的乐器',
      preferences: '选择您的偏好',
      order: '完成订单'
    },
    form: {
      series: {
        title: '选择您的系列',
        subtitle: '选择最适合您需求的系列'
      },
      color: {
        title: '选择您的颜色',
        subtitle: '表达自我 - 选择您的颜色'
      },
      fullName: '姓名',
      email: '电子邮箱',
      userRole: '您是？',
      country: '国家',
      message: '附加信息',
      gdpr: '我同意处理我的个人数据',
      submit: '完成预订',
      success: '感谢您的预订！我们将尽快与您联系。',
      error: '预订提交失败。请重试。'
    }
  },
  notification: {
    title: '保持更新',
    subtitle: '订阅我们的通讯',
    email: '输入您的邮箱',
    submit: '通知我',
    success: '谢谢！产品可用时我们会通知您。',
    error: '订阅失败。请重试。'
  },
  footer: {
    tagline: 'Elevoria Ltd是一家总部位于英国的公司，专门通过JMLWORLD.COM销售和分销JML弦乐器硬琴盒。如有英国境外的询问，请联系我们。',
    hardCaseFor: '适用于',
    company: '公司',
    legal: '法律',
    rights: '© {year} JMLWORLD由Elevoria Ltd提供支持。保留所有权利。'
  }
};