import { FormattedMessage } from 'react-intl';

export function TermsPage() {
  return (
    <div className="min-h-screen pt-32 pb-16 relative">
      <div className="absolute inset-0 bg-gradient-radial from-gray-50 to-white overflow-hidden">
        <div className="absolute inset-0 bg-grid opacity-[0.015]" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-semibold tracking-tight text-gray-900 mb-8 text-center">
            Terms and Conditions
          </h1>
          
          <div className="prose prose-gray max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <p>
                The website JMLWORLD.COM is operated by Elevoria Ltd, a company registered in the UK. These Terms and Conditions govern your use of our website and the purchase of products from us.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">2. Definitions</h2>
              <ul className="list-disc pl-6">
                <li>"We," "Us," "Our" refers to Elevoria Ltd, operating JMLWORLD.COM</li>
                <li>"You," "User," "Customer" refers to any person accessing or using our website</li>
                <li>"Website" refers to JMLWORLD.COM</li>
                <li>"Products" refers to the goods available for purchase on our website</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">3. Acceptance of Terms</h2>
              <p>
                By accessing or using our website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our website.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">4. Changes to Terms</h2>
              <p>
                We reserve the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting to the website. Your continued use of the website following any changes indicates your acceptance of the modified terms.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">5. Use of the Website</h2>
              <ul className="list-disc pl-6">
                <li>You must be at least 18 years old or have parental consent to use our website</li>
                <li>You agree not to use our website for any unlawful purpose</li>
                <li>You must not attempt to gain unauthorized access to our website or systems</li>
                <li>You must not introduce viruses or other malicious code</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">6. Product Information</h2>
              <p>
                We strive to ensure all product descriptions and images are accurate. However, slight variations in color and specifications may occur. We reserve the right to modify product specifications without notice.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">7. Ordering and Payment</h2>
              <ul className="list-disc pl-6">
                <li>Orders are subject to acceptance and availability</li>
                <li>Payment is required at the time of ordering</li>
                <li>We accept major credit/debit cards and other specified payment methods</li>
                <li>All prices are in GBP and include VAT where applicable</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">8. Pricing and Availability</h2>
              <ul className="list-disc pl-6">
                <li>Prices may change without notice</li>
                <li>Products are subject to availability</li>
                <li>We reserve the right to limit order quantities</li>
                <li>We may withdraw products from sale at any time</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">9. Delivery Information</h2>
              <ul className="list-disc pl-6">
                <li>Delivery times are estimates only</li>
                <li>Current estimated delivery time is approximately 20 days</li>
                <li>Additional charges may apply for international delivery</li>
                <li>We are not responsible for customs duties or import taxes</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">10. Cancellation and Returns</h2>
              <ul className="list-disc pl-6">
                <li>You have the right to cancel your order within 14 days of receipt</li>
                <li>Returns must be in original condition and packaging</li>
                <li>Return shipping costs are your responsibility</li>
                <li>Refunds will be processed within 14 days of receiving returned goods</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">11. Liability</h2>
              <p>
                Our liability is limited to the purchase price of the products. We are not liable for any indirect, consequential, or incidental damages to the extent permitted by law.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">12. Intellectual Property Rights</h2>
              <p>
                All content on our website is protected by copyright and other intellectual property rights owned by or licensed to us. Unauthorized use is prohibited.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">13. Third-Party Links</h2>
              <p>
                Our website may contain links to third-party websites. We are not responsible for the content or practices of these websites.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">14. Indemnity</h2>
              <p>
                You agree to indemnify us against any claims arising from your breach of these Terms and Conditions.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">15. Severability</h2>
              <p>
                If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall continue in full force and effect.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">16. Governing Law and Jurisdiction</h2>
              <p>
                These Terms are governed by the laws of England and Wales. Any disputes shall be subject to the exclusive jurisdiction of the courts of England and Wales.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">17. Contact Information</h2>
              <div className="mt-4">
                <p><strong>Elevoria Ltd.</strong></p>
                <p>Trading as: JMLWORLD.COM (exclusively on JML products).</p>
                <p>Official Partner of JML in the UK (primary) and EMEA.</p>
                <p>Email: contact@jmlworld.com</p>
              </div>
            </section>

            <div className="text-sm text-gray-500 mt-12 pt-8 border-t">
              Last updated: {new Date().toLocaleDateString('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}