import { supabase } from '../lib/supabase';
import { Inquiry } from '../types/inquiry';

const INQUIRIES_KEY = 'jmlworld_inquiries';

// Helper function to handle local storage
const getLocalInquiries = (): Inquiry[] => {
  try {
    const stored = localStorage.getItem(INQUIRIES_KEY);
    const inquiries = stored ? JSON.parse(stored) : [];
    return inquiries.sort((a: Inquiry, b: Inquiry) => 
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  } catch (error) {
    console.error('Error reading from local storage:', error);
    return [];
  }
};

const saveLocalInquiry = (inquiry: Inquiry): void => {
  try {
    const inquiries = getLocalInquiries();
    inquiries.unshift(inquiry);
    localStorage.setItem(INQUIRIES_KEY, JSON.stringify(inquiries));
  } catch (error) {
    console.error('Error saving to local storage:', error);
  }
};

const deleteLocalInquiries = (ids: string[]): void => {
  try {
    const inquiries = getLocalInquiries();
    const filteredInquiries = inquiries.filter(inquiry => !ids.includes(inquiry.id));
    localStorage.setItem(INQUIRIES_KEY, JSON.stringify(filteredInquiries));
  } catch (error) {
    console.error('Error deleting from local storage:', error);
  }
};

export const saveInquiry = async (inquiry: Omit<Inquiry, 'id' | 'status' | 'createdAt'>): Promise<Inquiry> => {
  const newInquiry: Inquiry = {
    ...inquiry,
    id: crypto.randomUUID(),
    status: 'new',
    createdAt: new Date().toISOString()
  };

  try {
    const { data, error } = await supabase
      .from('inquiries')
      .insert({
        id: newInquiry.id,
        full_name: inquiry.fullName,
        email: inquiry.email,
        user_role: inquiry.userRole,
        message: inquiry.message,
        country: inquiry.country,
        status: 'new',
        type: inquiry.type,
        product_type: inquiry.productType,
        series: inquiry.series,
        color: inquiry.color,
        address: inquiry.address,
        city: inquiry.city,
        postal_code: inquiry.postalCode,
        phone: inquiry.phone
      })
      .select()
      .single();

    if (error) throw error;

    return {
      id: data.id,
      fullName: data.full_name,
      email: data.email,
      userRole: data.user_role as Inquiry['userRole'],
      message: data.message,
      country: data.country,
      status: data.status,
      type: data.type,
      createdAt: data.created_at,
      productType: data.product_type,
      series: data.series,
      color: data.color,
      address: data.address,
      city: data.city,
      postalCode: data.postal_code,
      phone: data.phone
    };
  } catch (error) {
    console.warn('Falling back to local storage:', error);
    saveLocalInquiry(newInquiry);
    return newInquiry;
  }
};

export const getInquiries = async (): Promise<Inquiry[]> => {
  try {
    const { data, error } = await supabase
      .from('inquiries')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map(item => ({
      id: item.id,
      fullName: item.full_name,
      email: item.email,
      userRole: item.user_role as Inquiry['userRole'],
      message: item.message,
      country: item.country,
      status: item.status,
      type: item.type,
      createdAt: item.created_at,
      productType: item.product_type,
      series: item.series,
      color: item.color,
      address: item.address,
      city: item.city,
      postalCode: item.postal_code,
      phone: item.phone
    }));
  } catch (error) {
    console.warn('Falling back to local storage:', error);
    return getLocalInquiries();
  }
};

export const updateInquiryStatus = async (id: string, status: Inquiry['status']): Promise<void> => {
  try {
    const { error } = await supabase
      .from('inquiries')
      .update({ status })
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.warn('Falling back to local storage:', error);
    const inquiries = getLocalInquiries();
    const updatedInquiries = inquiries.map(inquiry =>
      inquiry.id === id ? { ...inquiry, status } : inquiry
    );
    localStorage.setItem(INQUIRIES_KEY, JSON.stringify(updatedInquiries));
  }
};

export const deleteInquiries = async (ids: string[]): Promise<void> => {
  try {
    const { error } = await supabase
      .from('inquiries')
      .delete()
      .in('id', ids);

    if (error) throw error;
  } catch (error) {
    console.warn('Falling back to local storage:', error);
    deleteLocalInquiries(ids);
  }
};