import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getInquiries, updateInquiryStatus, deleteInquiries } from '../../utils/storage';
import { Inquiry } from '../../types/inquiry';
import { 
  MessageSquare, 
  Settings,
  ShoppingBag
} from 'lucide-react';
import { Tab } from '../../components/admin/Tab';
import { InquiryTable } from '../../components/admin/InquiryTable';
import { Settings as SettingsComponent } from '../../components/admin/Settings';

export function AdminDashboardPage() {
  const { user, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('inquiries');
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);

  useEffect(() => {
    const fetchInquiries = async () => {
      const data = await getInquiries();
      setInquiries(data);
    };
    fetchInquiries();
  }, []);

  const handleStatusChange = async (id: string, status: Inquiry['status']) => {
    await updateInquiryStatus(id, status);
    const updatedInquiries = await getInquiries();
    setInquiries(updatedInquiries);
  };

  const handleDelete = async (ids: string[]) => {
    await deleteInquiries(ids);
    const updatedInquiries = await getInquiries();
    setInquiries(updatedInquiries);
  };

  const stats = {
    totalInquiries: inquiries.length,
    newInquiries: inquiries.filter(i => i.status === 'new').length,
    resolvedInquiries: inquiries.filter(i => i.status === 'resolved').length,
    conversionRate: inquiries.length > 0 
      ? `${((inquiries.filter(i => i.status === 'resolved').length / inquiries.length) * 100).toFixed(1)}%`
      : '0%'
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold">JMLWORLD.COM ADMIN DASHBOARD</h1>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-600">{user?.email}</span>
              <button
                onClick={logout}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-0 mb-6">
          <div className="flex space-x-4">
            <Tab
              icon={MessageSquare}
              label="Inquiries"
              count={stats.newInquiries}
              isActive={activeTab === 'inquiries'}
              onClick={() => setActiveTab('inquiries')}
            />
            <Tab
              icon={ShoppingBag}
              label="Reservations"
              count={inquiries.filter(i => i.type === 'reservation' && i.status === 'new').length}
              isActive={activeTab === 'reservations'}
              onClick={() => setActiveTab('reservations')}
            />
            <Tab
              icon={Settings}
              label="Settings"
              isActive={activeTab === 'settings'}
              onClick={() => setActiveTab('settings')}
            />
          </div>
        </div>

        <div className="px-4 py-6 sm:px-0">
          {activeTab === 'inquiries' && (
            <InquiryTable
              inquiries={inquiries.filter(i => i.type === 'contact')}
              onStatusChange={handleStatusChange}
              onDelete={handleDelete}
            />
          )}

          {activeTab === 'reservations' && (
            <InquiryTable
              inquiries={inquiries.filter(i => i.type === 'reservation')}
              onStatusChange={handleStatusChange}
              onDelete={handleDelete}
            />
          )}

          {activeTab === 'settings' && <SettingsComponent />}
        </div>
      </main>
    </div>
  );
}