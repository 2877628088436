import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/admin/ProtectedRoute';
import { messages } from './i18n/messages';
import { Layout } from './components/Layout';
import { Hero } from './components/Hero';
import { Features } from './components/Features';
import { Contact } from './components/Contact';
import { ContactUsPage } from './pages/ContactUs';
import { CellistPage } from './pages/Cellist';
import { ViolinistPage } from './pages/Violinist';
import { ViolistPage } from './pages/Violist';
import { ReservePage } from './pages/Reserve';
import { AboutPage } from './pages/About';
import { PrivacyPage } from './pages/Privacy';
import { TermsPage } from './pages/Terms';
import { AdminLoginPage } from './pages/admin/Login';
import { AdminDashboardPage } from './pages/admin/Dashboard';

function AppContent() {
  const [locale, setLocale] = useState('en');
  const [currentPage, setCurrentPage] = useState('home');
  const location = useLocation();

  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="en">
      <AuthProvider>
        <Helmet>
          <html lang={locale} />
          <title>JML Carbon Fibre Cases | Premium Hard Cases for String Instruments</title>
          <meta name="description" content="Discover JML's premium carbon fibre hard cases for cellos, violins, and violas. Ultra-lightweight, durable protection starting from £1,190. Free worldwide shipping available." />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          
          {/* Keywords */}
          <meta name="keywords" content="carbon fibre cello case, violin hard case, viola case, lightweight instrument case, JML cases, string instrument protection, professional music cases, carbon fiber violin case" />
          
          {/* Open Graph tags */}
          <meta property="og:title" content="JML Carbon Fibre Cases | Premium Hard Cases for String Instruments" />
          <meta property="og:description" content="Discover JML's premium carbon fibre hard cases for cellos, violins, and violas. Ultra-lightweight, durable protection starting from £1,190. Free worldwide shipping available." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://jmlworld.com" />
          <meta property="og:image" content="https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-black.png" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="855" />
          <meta property="og:image:alt" content="JML CL6 Series Carbon Fibre Cello Case in Black" />
          <meta property="og:site_name" content="JML World" />
          
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@jmlworld" />
          <meta name="twitter:title" content="JML Carbon Fibre Cases | Premium Hard Cases for String Instruments" />
          <meta name="twitter:description" content="Discover JML's premium carbon fibre hard cases for cellos, violins, and violas. Ultra-lightweight, durable protection starting from £1,190. Free worldwide shipping available." />
          <meta name="twitter:image" content="https://jmlworld.s3.eu-north-1.amazonaws.com/jmlworld_cello_CL6_series_collection/jml-world-cello-carbon-fibre-hardcase-cl6-black.png" />
          <meta name="twitter:image:alt" content="JML CL6 Series Carbon Fibre Cello Case in Black" />
        </Helmet>

        <Routes>
          {/* Admin Routes */}
          <Route path="/admin">
            <Route index element={<Navigate to="/admin/login" replace />} />
            <Route path="login" element={<AdminLoginPage />} />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboardPage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Public Routes */}
          <Route
            path="/"
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <Hero setCurrentPage={setCurrentPage} />
                <Features />
                <Contact setCurrentPage={setCurrentPage} />
              </Layout>
            }
          />
          <Route 
            path="/contact-us" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <ContactUsPage />
              </Layout>
            }
          />
          <Route 
            path="/cellist" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <CellistPage />
              </Layout>
            }
          />
          <Route 
            path="/violinist" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <ViolinistPage />
              </Layout>
            }
          />
          <Route 
            path="/violist" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <ViolistPage />
              </Layout>
            }
          />
          <Route 
            path="/reserve" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <ReservePage />
              </Layout>
            }
          />
          <Route 
            path="/about" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <AboutPage />
              </Layout>
            }
          />
          <Route 
            path="/privacy" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <PrivacyPage />
              </Layout>
            }
          />
          <Route 
            path="/terms" 
            element={
              <Layout setLocale={setLocale} setCurrentPage={setCurrentPage}>
                <TermsPage />
              </Layout>
            }
          />

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        <Toaster position="top-right" />
      </AuthProvider>
    </IntlProvider>
  );
}

export default function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}