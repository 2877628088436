import { ReactNode } from 'react';
import { Navigation } from './Navigation';
import { Footer } from './Footer';

interface LayoutProps {
  children: ReactNode;
  setLocale: (locale: string) => void;
  setCurrentPage: (page: string) => void;
}

export function Layout({ children, setLocale, setCurrentPage }: LayoutProps) {
  return (
    <div className="min-h-screen bg-white">
      <Navigation setLocale={setLocale} setCurrentPage={setCurrentPage} />
      <main>{children}</main>
      <Footer setCurrentPage={setCurrentPage} />
    </div>
  );
}