import { createContext, useContext, useState, useEffect } from 'react';
import { User, AuthState, LoginCredentials } from '../types/auth';
import toast from 'react-hot-toast';

interface AuthContextType extends AuthState {
  login: (credentials: LoginCredentials) => Promise<void>;
  logout: () => void;
  checkPermission: (permission: string) => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Hardcoded admin credentials for demo
const ADMIN_CREDENTIALS = {
  email: 'admin@jmlworld.com',
  password: 'admin123'
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>({
    user: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const storedUser = localStorage.getItem('auth_user');
        if (!storedUser) {
          setState({ user: null, isLoading: false, error: null });
          return;
        }

        setState({ 
          user: JSON.parse(storedUser), 
          isLoading: false, 
          error: null 
        });
      } catch (error) {
        setState({ user: null, isLoading: false, error: error as Error });
      }
    };

    checkAuth();
  }, []);

  const login = async (credentials: LoginCredentials): Promise<void> => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));

      // Simple credential check
      if (
        credentials.email === ADMIN_CREDENTIALS.email && 
        credentials.password === ADMIN_CREDENTIALS.password
      ) {
        const user: User = {
          id: '1',
          email: credentials.email,
          name: 'Admin User',
          role: 'admin',
          permissions: ['manage_inquiries', 'manage_users', 'view_analytics'],
        };

        localStorage.setItem('auth_user', JSON.stringify(user));
        setState({ user, isLoading: false, error: null });
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      localStorage.removeItem('auth_user');
      setState({ user: null, isLoading: false, error: error as Error });
      toast.error('Invalid credentials');
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('auth_user');
    setState({ user: null, isLoading: false, error: null });
  };

  const checkPermission = (permission: string) => {
    return state.user?.permissions.includes(permission) ?? false;
  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout, checkPermission }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}