import { useTranslation } from '../contexts/TranslationContext';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'zh', name: '中文' }
];

export function LanguageSelector() {
  const { locale, setLocale } = useTranslation();
  
  return (
    <div className="relative">
      <label htmlFor="language-select" className="sr-only">
        Select language
      </label>
      <select
        id="language-select"
        onChange={(e) => setLocale(e.target.value)}
        value={locale}
        className="appearance-none bg-transparent text-sm border border-gray-300 rounded-md px-2 py-1 pr-8 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:border-transparent cursor-pointer"
        aria-label="Select language"
      >
        {languages.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
  );
}