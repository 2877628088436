import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { submitToWeb3Forms } from '../../utils/web3forms';
import toast from 'react-hot-toast';

interface NotificationFormProps {
  productType: string;
}

export function NotificationForm({ productType }: NotificationFormProps) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    try {
      setIsSubmitting(true);
      
      const result = await submitToWeb3Forms({
        name: `${productType} Interest`,
        email: email,
        subject: `New ${productType} Notification Request`,
        message: `Please notify me when ${productType} cases become available.`,
        from_name: 'JMLWORLD Notification Form'
      });

      if (result.success) {
        toast.success(`Thank you! We'll notify you when ${productType} cases become available.`);
        setEmail('');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-4">
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="flex-1 rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        disabled={isSubmitting}
      />
      <button 
        type="submit" 
        className="apple-primary-button disabled:opacity-50"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <div className="flex items-center">
            <Loader2 className="h-4 w-4 animate-spin mr-2" />
            Sending...
          </div>
        ) : (
          'Notify Me'
        )}
      </button>
    </form>
  );
}