import { useTranslation } from '../contexts/TranslationContext';
import { Zap, Shield, Globe } from 'lucide-react';

export function Features() {
  const { t } = useTranslation();

  const features = [
    { icon: Zap, titleKey: 'features.ultraLight.title', descKey: 'features.ultraLight.desc' },
    { icon: Shield, titleKey: 'features.protection.title', descKey: 'features.protection.desc' },
    { icon: Globe, titleKey: 'features.shipping.title', descKey: 'features.shipping.desc' }
  ];

  return (
    <section id="features" className="py-16 bg-gray-50" aria-labelledby="features-heading">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 id="features-heading" className="sr-only">
          {t('features.title')}
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {features.map(({ icon: Icon, titleKey, descKey }, index) => (
            <div 
              key={titleKey}
              className="feature-card bg-white p-6 rounded-2xl"
              style={{ animationDelay: `${0.2 * index}s` }}
            >
              <Icon className="h-12 w-12 text-gray-900 mb-4" aria-hidden="true" />
              <h3 className="text-xl font-semibold mb-2 text-gray-900">
                {t(titleKey)}
              </h3>
              <p className="text-gray-600">
                {t(descKey)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}