import { useTranslation } from '../contexts/TranslationContext';
import { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ProductTab {
  id: 'cl6' | 'cl7';
  image: string;
}

const PRODUCT_TABS: ProductTab[] = [
  {
    id: 'cl6',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/CL6_all_carbonfibre_cellohardcase_800x855.png',
  },
  {
    id: 'cl7',
    image: 'https://jmlworld.s3.eu-north-1.amazonaws.com/CL7_all_carbonfibre_cellohardcase_800x855.png',
  },
];

interface HeroProps {
  setCurrentPage?: (page: string) => void;
}

export function Hero({ setCurrentPage }: HeroProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ProductTab['id']>('cl6');
  const navigate = useNavigate();

  const handleDiscoverClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (setCurrentPage) {
      setCurrentPage('cellist');
    }
    navigate('/cellist');
    window.scrollTo(0, 0);
  };

  return (
    <section className="pt-32 pb-16 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-hero font-semibold tracking-tighter text-gray-900 mb-6 section-fade-in">
            {t('hero.welcome')}{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#9abba9] via-[#ccc9bf] to-[#bbae56]">
              JMLWORLD
            </span>
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto section-fade-in" style={{ animationDelay: '0.2s' }}>
            {t('hero.subtitle')}
          </p>

          <div className="flex justify-center mb-12 section-fade-in" style={{ animationDelay: '0.3s' }}>
            <button
              onClick={handleDiscoverClick}
              className="apple-primary-button group"
            >
              {t('common.discover')}
              <ArrowRight className="inline-block ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
            </button>
          </div>

          <div className="mt-12 section-fade-in" style={{ animationDelay: '0.3s' }}>
            <div className="flex justify-center space-x-1 mb-8" role="tablist">
              {PRODUCT_TABS.map((tab) => (
                <button
                  key={tab.id}
                  role="tab"
                  aria-selected={activeTab === tab.id}
                  aria-controls={`${tab.id}-panel`}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    px-8 py-3 text-sm font-medium rounded-full transition-all duration-300
                    ${activeTab === tab.id
                      ? 'bg-black text-white shadow-lg'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }
                  `}
                >
                  {t(`hero.${tab.id}.title`)}
                </button>
              ))}
            </div>

            {PRODUCT_TABS.map((tab) => (
              <div
                key={tab.id}
                role="tabpanel"
                id={`${tab.id}-panel`}
                aria-labelledby={`${tab.id}-tab`}
                className={`transition-opacity duration-300 ${
                  activeTab === tab.id ? 'opacity-100' : 'opacity-0 hidden'
                }`}
              >
                <div className="max-w-2xl mx-auto">
                  <div className="relative aspect-[800/855] w-full">
                    <img
                      src={tab.image}
                      alt={t(`hero.${tab.id}.imageAlt`)}
                      className="object-contain w-full h-full"
                      width="800"
                      height="855"
                      loading={tab.id === 'cl6' ? 'eager' : 'lazy'}
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}