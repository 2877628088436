export default {
  common: {
    required: '* Required',
    loading: 'Loading...',
    error: 'Something went wrong',
    success: 'Success!',
    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    discover: 'Discover'
  },
  navigation: {
    cellist: 'Cellist',
    violinist: 'Violinist',
    violist: 'Violist',
    about: 'About',
    contact: 'Contact',
    reserve: 'Reserve',
    getStarted: 'Reserve'
  },
  hero: {
    welcome: 'Welcome to',
    subtitle: 'JMLWORLD Offers JML\'s Ultra-Light Carbon Fibre Cello Hard Cases—Versatile Protection for String Instruments Making Musicians\' Lives Easier',
    cl6: {
      title: 'CL6 Series',
      imageAlt: 'JML CL6 Series Carbon Fibre Cello Hard Case'
    },
    cl7: {
      title: 'CL7 Series',
      imageAlt: 'JML CL7 Series Carbon Fibre Cello Hard Case'
    }
  },
  features: {
    title: 'Features',
    ultraLight: {
      title: 'Ultra-Light Design',
      desc: 'Experience unparalleled mobility with our lightweight carbon fibre design.'
    },
    protection: {
      title: 'Superior Protection',
      desc: 'Multi-layer carbon fibre construction provides exceptional strength and impact resistance.'
    },
    shipping: {
      title: 'Shipping',
      desc: 'Safely delivered right to your doorstep.'
    }
  },
  contact: {
    title: 'Get in Touch',
    message: 'We\'d love to hear from you',
    cta: 'Contact Us',
    form: {
      fullName: 'Full name',
      email: 'Email address',
      subject: 'Subject',
      message: 'Your message',
      gdpr: 'I agree to the processing of my personal data in accordance with the Privacy Policy',
      required: '* Required fields',
      submit: 'Send Message',
      success: 'Message sent successfully! We will get back to you soon.',
      error: 'Failed to send message. Please try again.'
    }
  },
  reserve: {
    title: 'Reserve Your Case',
    subtitle: 'Select your instrument and customize your order.',
    howItWorks: 'How It Works',
    steps: {
      instrument: 'Select your instrument',
      preferences: 'Choose your preferences',
      order: 'Complete your order'
    },
    form: {
      series: {
        title: 'Select Your Series',
        subtitle: 'Choose the perfect series for your needs'
      },
      color: {
        title: 'Select Your Color',
        subtitle: 'Express yourself - choose your colour'
      },
      fullName: 'Full Name',
      email: 'Email Address',
      userRole: 'Who are you?',
      country: 'Country',
      message: 'Additional Message',
      gdpr: 'I agree to the processing of my personal data',
      submit: 'Complete Reservation',
      success: 'Thank you for your reservation! We will contact you shortly.',
      error: 'Failed to submit reservation. Please try again.'
    }
  },
  notification: {
    title: 'Stay Updated',
    subtitle: 'Sign up for our newsletter',
    email: 'Enter your email',
    submit: 'Notify Me',
    success: 'Thank you! We will notify you when available.',
    error: 'Failed to subscribe. Please try again.'
  },
  footer: {
    tagline: 'Elevoria Ltd is a UK-based limited company specializing in the sale and distribution of JML hard cases for string instruments via JMLWORLD.COM. For any inquiries outside the UK, please contact us.',
    hardCaseFor: 'Hard Case for',
    company: 'Company',
    legal: 'Legal',
    rights: '© {year} JMLWORLD is powered by Elevoria Ltd.. All rights reserved.'
  }
};