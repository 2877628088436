import { FormattedMessage } from 'react-intl';
import { AlertCircle } from 'lucide-react';
import { NotificationForm } from '../components/forms/NotificationForm';

export function ViolinistPage() {
  return (
    <div className="min-h-screen pt-32 pb-16 relative">
      <div className="absolute inset-0 bg-gradient-radial from-gray-50 to-white overflow-hidden">
        <div className="absolute inset-0 bg-grid opacity-[0.015]" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-4xl font-semibold tracking-tight text-gray-900 mb-6 text-center">
            JML Carbon Fibre Violin Cases
          </h1>
          
          <div className="text-center mb-12">
            <p className="text-xl text-gray-600">
              Coming Soon - Premium Protection for Your Violin
            </p>
          </div>

          <div className="max-w-2xl mx-auto bg-white rounded-2xl shadow-lg p-8">
            <div className="flex items-start space-x-4">
              <AlertCircle className="w-6 h-6 text-indigo-600 flex-shrink-0 mt-1" />
              <div className="space-y-4">
                <h2 className="text-xl font-semibold">Stay Updated</h2>
                <p className="text-gray-600">
                  We're currently developing our violin case collection. Sign up for our newsletter to be notified when they become available.
                </p>
                <NotificationForm productType="Violin" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}