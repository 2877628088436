import { Helmet } from 'react-helmet-async';
import { ContactForm } from '../components/forms/ContactForm';
import { ContactHero } from '../components/contact/ContactHero';

export function ContactUsPage() {
  return (
    <>
      <Helmet>
        <title>Contact Us | JMLWORLD.COM</title>
        <meta 
          name="description" 
          content="Get in touch with JMLWORLD.COM. We're here to help with any questions about our premium carbon fibre cases for string instruments."
        />
      </Helmet>

      <div className="min-h-screen pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <ContactHero />
          
          <div className="max-w-2xl mx-auto">
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
              <div className="p-8">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}