import { FormattedMessage } from 'react-intl';
import { CelloSlider } from '../components/CelloSlider';
import { AlertCircle } from 'lucide-react';
import { useState } from 'react';

interface SeriesData {
  id: 'cl6' | 'cl7';
  title: string;
  subtitle: string;
  weight: string;
  price: string;
  description: string;
  specifications: {
    weight: string;
    size: string;
    material: string;
    features: string[];
  };
}

const SERIES_DATA: SeriesData[] = [
  {
    id: 'cl6',
    title: 'CL6 ULTRALIGHT SERIES',
    subtitle: '2.8 KG CARBON FIBRE CELLO CASE',
    weight: '2.8kg',
    price: '£1,190',
    description: `The JML CL-6 Ultralight series cello case is ideal for the discerning musician seeking lightness, strength, and protection, all elegantly packaged in a stylish and versatile case.

Designed for musicians, JML is a high-tech company dedicated to creating high-end instrument cases that not only offer ultimate protection but are also beautiful, striking to behold and accessible.`,
    specifications: {
      weight: '2.8kg',
      size: '4/4 (can fit a 3/4)',
      material: 'Carbon Fibre (outer shell)',
      features: [
        'Super lightweight: crafted from carbon fibre, the outer shell offers exceptional strength without the added weight, ensuring easy portability without compromising protection.',
        'Soft padded suspension system: the interior features a soft padded suspension system designed to shield your instrument from bumps and knocks when on the move.',
        'Comfortable carrying options: equipped with padded backpack straps and soft-touch carry handles on both the top and side, the case provides versatile and comfortable carrying solutions.',
        'Two bow holders: inside, you\'ll find secure spinners accommodating two bows, keeping them safe and readily accessible.',
        'Removable accessories pouch: a detachable pouch is included for convenient storage of accessories and strings, helping you stay organised.',
        'Protective padded case cover: the case comes with a padded cover to protect the exterior from scratches and wear while travelling.'
      ]
    }
  },
  {
    id: 'cl7',
    title: 'CL7 ULTRALIGHT SERIES',
    subtitle: '2.7 KG CARBON FIBRE CELLO CASE',
    weight: '2.7kg',
    price: '£1,390',
    description: `Experience unparalleled elegance and protection with the JML CL-7 Series Cello Hard Case. Weighing just 2.7 kg, this ultra-lightweight case features a smooth, soft-touch carbon fibre exterior that is highly scratch-resistant, ensuring lasting beauty and durability. The interior boasts a built-in sponge support cushion that provides exceptional protection for your instrument. For versatile carrying options, it comes with detachable double-safety shoulder straps and the option to add rolling backpack straps. The CL-7 combines luxurious design with advanced features, making it the perfect choice for musicians seeking both style and functionality.`,
    specifications: {
      weight: '2.7kg',
      size: '4/4 (can fit a 3/4)',
      material: 'Carbon Fibre (outer shell)',
      features: [
        'Ultra-lightweight and durable: Crafted from high-carbon fibre, the CL7 series offers a smooth and soft-to-touch finish akin to skin, combining elegance with exceptional durability. Its highly scratch-resistant surface ensures your case maintains its pristine look, even with frequent use.',
        'Enhanced interior protection: The CL7 series features a built-in sponge support cushion designed to cradle your instrument securely, providing robust protection against impacts during transport.',
        'Advanced carrying options: Designed for maximum comfort and versatility, the case is equipped with detachable double-safety shoulder straps.',
        'Compact and secure storage: While maintaining its lightweight design, the case offers innovative storage solutions with spacious compartments and a streamlined interior to keep your instrument and essentials safe.',
        'Scratch-resistant elegance: The CL7 series features a premium coating that resists scratches and abrasions, ensuring that the case remains as refined as your instrument.',
        'Protective padded case cover: the case comes with a padded cover to protect the exterior from scratches and wear while travelling.'
      ]
    }
  }
];

export function CellistPage() {
  const [activeSeries, setActiveSeries] = useState<SeriesData['id']>('cl6');
  const currentSeries = SERIES_DATA.find(series => series.id === activeSeries)!;

  return (
    <div className="min-h-screen pt-32 pb-16 relative">
      <div className="absolute inset-0 bg-gradient-radial from-gray-50 to-white overflow-hidden">
        <div className="absolute inset-0 bg-grid opacity-[0.015]" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-4xl font-semibold tracking-tight text-gray-900 mb-6 text-center">
            <FormattedMessage id="cellist.title" />
          </h1>
          
          <div className="space-y-12">
            <div className="text-center">
              <p className="text-xl text-gray-600 mb-12">
                <FormattedMessage id="cellist.subtitle" />
              </p>

              {/* Series Selection Tabs */}
              <div className="flex justify-center space-x-4 mb-12">
                {SERIES_DATA.map((series) => (
                  <button
                    key={series.id}
                    onClick={() => setActiveSeries(series.id)}
                    className={`
                      px-8 py-3 rounded-full transition-all duration-300
                      ${activeSeries === series.id
                        ? 'bg-gray-900 text-white shadow-lg'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }
                    `}
                  >
                    {series.title}
                  </button>
                ))}
              </div>
              
              {/* Two Column Layout */}
              <div className="flex flex-col lg:flex-row gap-12 items-start text-left">
                {/* Price and Slider Column */}
                <div className="lg:w-[400px] flex-shrink-0 mx-auto lg:mx-0">
                  <div className="mb-6 text-center">
                    <div className="inline-block bg-gray-900 text-white px-6 py-2 rounded-full">
                      <span className="text-2xl font-bold">Price: {currentSeries.price}</span>
                    </div>
                  </div>
                  <CelloSlider series={activeSeries} />
                  
                  {/* Delivery Information */}
                  <div className="mt-12 p-6 bg-gray-50 rounded-xl border border-gray-200">
                    <div className="flex items-start space-x-3">
                      <AlertCircle className="w-6 h-6 text-gray-900 flex-shrink-0 mt-1" />
                      <div className="space-y-4">
                        <h3 className="font-semibold text-lg text-gray-900">Delivery Information</h3>
                        <div className="space-y-3 text-gray-600 text-sm">
                          <p>
                            Due to high demand, JMLWorld is currently accepting reservations only.
                          </p>
                          <p>
                            New stock batches arrive regularly, and we recommend that customers place orders and complete payment online to secure their items and avoid disappointment.
                          </p>
                          <p>
                            Please note that the current estimated lead time for delivery is approximately +30 days.
                          </p>
                          <p>
                            We appreciate your patience and understanding. For any inquiries, please contact us.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Specifications Column */}
                <div className="flex-1 space-y-6">
                  <div className="prose prose-gray max-w-none">
                    <div className="mb-8">
                      <h2 className="text-[#bbae56] font-bold tracking-wider">
                        <div className="text-3xl mb-2">{currentSeries.title}</div>
                        <div className="text-2xl">{currentSeries.subtitle}</div>
                      </h2>
                    </div>

                    <h2 className="text-2xl font-semibold mb-4">Description</h2>
                    <div className="text-gray-600 mb-8 whitespace-pre-line">
                      {currentSeries.description}
                    </div>
                    
                    <h3 className="text-xl font-semibold mt-8 mb-4">Specifications</h3>
                    <div className="space-y-6">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <ul className="space-y-2 text-gray-600">
                          <li><strong>Weight:</strong> {currentSeries.specifications.weight}</li>
                          <li><strong>Size:</strong> {currentSeries.specifications.size}</li>
                          <li><strong>Material:</strong> {currentSeries.specifications.material}</li>
                        </ul>
                      </div>
                      
                      <ul className="space-y-4 text-gray-600">
                        {currentSeries.specifications.features.map((feature, index) => (
                          <li key={index} className="flex items-start">
                            <span className="font-semibold mr-2">•</span>
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}