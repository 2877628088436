import { z } from 'zod';
import { WEB3FORMS_ACCESS_KEY } from './constants';

const WEB3FORMS_ENDPOINT = 'https://api.web3forms.com/submit';

// Schema for form data validation
const formDataSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  subject: z.string().min(5, 'Subject must be at least 5 characters'),
  message: z.string().min(10, 'Message must be at least 10 characters'),
  from_name: z.string().optional()
});

type FormData = z.infer<typeof formDataSchema>;

interface Web3FormsResponse {
  success: boolean;
  message: string;
}

export const submitToWeb3Forms = async (formData: FormData): Promise<Web3FormsResponse> => {
  try {
    // Validate form data before submission
    const validatedData = formDataSchema.parse(formData);

    const payload = {
      access_key: WEB3FORMS_ACCESS_KEY,
      ...validatedData,
      subject: `New Contact Form Submission: ${validatedData.subject}`,
      from_name: validatedData.from_name || 'JMLWORLD Contact Form'
    };

    const response = await fetch(WEB3FORMS_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (!data.success) {
      throw new Error(data.message || 'Form submission failed');
    }

    return data;
  } catch (error) {
    console.error('Web3Forms submission error:', error);
    throw error;
  }
};