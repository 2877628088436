import { useTranslation } from '../contexts/TranslationContext';
import { useNavigate } from 'react-router-dom';

interface FooterProps {
  setCurrentPage?: (page: string) => void;
}

export function Footer({ setCurrentPage }: FooterProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavClick = (e: React.MouseEvent, page: string) => {
    e.preventDefault();
    if (setCurrentPage) {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    navigate(`/${page}`);
  };

  const footerLinks = [
    {
      title: 'footer.hardCaseFor',
      links: [
        { href: '/cellist', messageId: 'navigation.cellist' },
        { href: '/violinist', messageId: 'navigation.violinist' },
        { href: '/violist', messageId: 'navigation.violist' }
      ]
    },
    {
      title: 'footer.company',
      links: [
        { href: '/about', messageId: 'navigation.about' },
        { href: '/contact-us', messageId: 'navigation.contact' }
      ]
    },
    {
      title: 'footer.legal',
      links: [
        { href: '/privacy', label: 'Privacy' },
        { href: '/terms', label: 'Terms' }
      ]
    }
  ];

  return (
    <footer className="bg-gray-100 py-12" role="contentinfo">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-4 gap-8 text-sm">
          <div>
            <h3 className="font-semibold mb-4">Elevoria</h3>
            <p className="text-gray-600">
              {t('footer.tagline')}
            </p>
          </div>
          {footerLinks.map(({ title, links }) => (
            <nav key={title} aria-label={t(title)}>
              <h4 className="font-semibold mb-4">
                {t(title)}
              </h4>
              <ul className="space-y-2">
                {links.map(link => (
                  <li key={link.messageId || link.label}>
                    <a 
                      href={link.href}
                      onClick={(e) => handleNavClick(e, link.href.replace('/', ''))}
                      className="text-gray-600 hover:text-gray-900 cursor-pointer"
                    >
                      {link.messageId ? t(link.messageId) : link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          ))}
        </div>
        <div className="border-t border-gray-200 mt-8 pt-8 text-center text-sm text-gray-600">
          {t('footer.rights', { year: new Date().getFullYear() })}
        </div>
      </div>
    </footer>
  );
}